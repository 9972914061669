import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {

  static async createStep(values, step, version, id) {
    try {
      var carroRes;
      var data = {};
      var lenght = 0;

      //Para el primer step del formulario
      if (step == 1) {
        //Para el primera interacción
        if (version == 1) {
          data = {
            name: values[0],
            email: values[1],
            salud: values[2],
            edad: values[3],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            lenght: 1,
          };

          carroRes = await axios.post(
            `${VUE_APP_SHIELD_URL}/step/create`,
            params
          );
        }
        //Para las demás interacciones interacción
        else {
          values.forEach((element) => {
            if (element != null) {
              lenght = lenght + 1;
            }
          });
          data = {
            name: values[0],
            email: values[1],
            salud: values[2],
            edad: values[3],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            id: id,
            lenght: lenght,
          };

          carroRes = await axios.put(
            `${VUE_APP_SHIELD_URL}/step/update`,
            params
          );
        }
      }
      //Para el segundo step del formulario
      else if (step == 2) {
        const params = {
          sponsor: VUE_APP_SPONSOR,
          steptwo: values,
          id: id,
          lenght: 5,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }
      else if (step == 4) {
        values.forEach((element) => {
          if (element != null) {
            lenght = lenght + 1;
          }
        });
        lenght = lenght + 1;
        data = {
          name: values[0],
          surname: values[1],
          serialNumber: values[2],
          rut: values[3],
          region: values[4],
          commune: values[5],
          address: values[6],
          email: values[7],
          phoneNumber: values[8],
        };
        const params = {
          sponsor: VUE_APP_SPONSOR,
          stepthree: data,
          id: id,
          lenght: lenght,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }

      return carroRes;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el Step",
      };
    }
  }

  static async createContactActiveCampain(element) {

    try {
      const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/activeCampain/createContact`,
        {
          element,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      // console.log("respuesta de shield: ", shieldRes.data)

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el contacto",
      };
    }
  }

  static async updateContactActiveCampain(element, planData, contactID, index) {

    try {

      const shieldRes = await axios.put(`${VUE_APP_SHIELD_URL}/activeCampain/updateContact`,
        {
          element,
          planData,
          contactID,
          index,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async getContactActiveCampain(email) {

    try {

      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/activeCampain/getContact/${email}`);

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  //Lógica para redireccionar los planes cuando tiene un código de descuento
  static async getPlans(forecast, benef, age, code) {

    //Obtener los parámetros para buscar planes
    var cantBenf;
    var category;
    var maxAge = age;

    if (forecast == "Isapre" && benef == "Individual" && maxAge >= 18 && maxAge <= 39) {
      category = "ISAPRE01"; //Ver Tarifario
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 40 && maxAge <= 49) {
      category = "ISAPRE02";
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 50 && maxAge <= 59) {
      category = "ISAPRE03";
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 60 && maxAge <= 69) {
      category = "ISAPRE04";
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 70 && maxAge <= 79) {
      category = "ISAPRE05";
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 80) {
      category = "ISAPRE06";
      cantBenf = "Plan Individual Isapre";
    }
    else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 0 && maxAge <= 39) {
      category = "ISAPRE07";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 40 && maxAge <= 49) {
      category = "ISAPRE08";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 50 && maxAge <= 59) {
      category = "ISAPRE09";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 60 && maxAge <= 69) {
      category = "ISAPRE10";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 70 && maxAge <= 79) {
      category = "ISAPRE11";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 80) {
      category = "ISAPRE12";
      cantBenf = "Plan Familiar Isapre";
    }
    else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 18 && maxAge <= 39) {
      //Inicio Individual Fonasa
      category = "FONASA01";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 40 && maxAge <= 49) {
      category = "FONASA02";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 50 && maxAge <= 59) {
      category = "FONASA03";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 60 && maxAge <= 69) {
      category = "FONASA04";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 70 && maxAge <= 79) {
      category = "FONASA05";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 80) {
      category = "FONASA06";
      cantBenf = "Plan Individual Fonasa";
    }
    else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 0 && maxAge <= 39) {
      category = "FONASA07";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 40 && maxAge <= 49) {
      category = "FONASA08";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 50 && maxAge <= 59) {
      category = "FONASA09";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 60 && maxAge <= 69) {
      category = "FONASA10";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 70 && maxAge <= 79) {
      category = "FONASA11";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 80) {
      category = "FONASA12";
      cantBenf = "Plan Familiar Fonasa";
    }
    try {
      var url = `${VUE_APP_SHIELD_URL}/oncologico`
      var params = "";

      if (code) {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=${category}&cantBenf=${cantBenf}&age=${maxAge}&code=${code}`
      } else {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=${category}&cantBenf=${cantBenf}&age=${maxAge}`
      }
      //Llamada al controlador oncologico en SHIELD, para la busqueda de planes
      const shieldRes = await axios.get(
        params
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener los planes",
      };
    }
  }

  static async getPeople(personID) {

    try {

      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/person/get/${personID}`,
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener los planes",
      };
    }
  }

  static async createInsurance(person, beneficiaries, plan, stepId) {

    person.forecast = person.salud
    person.birthdate = person.dates

    //Generar Array ordenado de Beneficiarios
    var BenefData = [];

    if (person.typePlan == 'Familiar') {
      beneficiaries.forEach((benef) => {
        BenefData.push({
          name: benef.name,
          surname: benef.surname,
          rut: benef.rut,
          parentesco: benef.tipo,
          fechaNacimiento: '' + benef.day + '-' + benef.month + '-' + benef.year,
        });
      });
    }
    
    person.BenefData = BenefData;

    //Se genera la petición a SIELD para generar la Insurance y conección a Instanda
    const jsonData = { person, planId: plan[0]._id, stepId: stepId };

    const shieldRes = await axios.post(
      // `${VUE_APP_SHIELD_URL}/oncologico`,
      `${VUE_APP_SHIELD_URL}/oncologico/createWithRunWayPayment`,
      jsonData
    );
    // console.log(shieldRes.data)
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }


  static async createInsuranceWithToku(person, beneficiaries, plan, stepId) {

    person.forecast = person.salud
    person.birthdate = person.dates

    //Generar Array ordenado de Beneficiarios
    var BenefData = [];
    if (beneficiaries) {
      beneficiaries.forEach((benef) => {
        BenefData.push({
          name: benef.name,
          surname: benef.surname,
          rut: benef.rut,
          parentesco: benef.tipo,
          fechaNacimiento: '' + benef.day + '-' + benef.month + '-' + benef.year,
        });
      });
      person.BenefData = BenefData;
    }

    var sponsorTokuRedirect = process.env.VUE_APP_SPONSOR_TOKU_REDIRECT

    //Se genera la petición a SIELD para generar la Insurance y conección a Instanda
    const jsonData = { person, planId: plan[0]._id, stepId: stepId, sponsorTokuRedirect: sponsorTokuRedirect };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/oncologico/createWithToku`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }
}