<template>
  <v-container style="margin:auto">

    <NavBar :step="1"></NavBar>

    <Titulo></Titulo>

    <br v-if="screen <= 800">
    <br v-if="screen <= 800">
    <br v-if="screen <= 800">

    <v-stepper v-model="e1" elevation="0">

      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">
      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="2">

          <div class="formData">

            <v-form ref="form" v-model="valid" max-witdh="300px">

              <br>
              <br>

              <h3 class="text-center labelTituloo">Estos son nuestros planes</h3>

              <h4 class="text-center labelSubtitulo">Elige tu plan</h4>

              <br>

              <!-- <v-row>
                &nbsp;&nbsp;&nbsp;
                <v-text-field :class="labelDescuento" v-model="userData.couponId"
                  label="Ingresa aquí tu código de descuento" maxlength="51" :rules="couponRules" solo></v-text-field>

                <v-btn class="botonAplicar" style="background-color: #FFB600; color: white; width: 70px; height: 50px;"
                  solo @click="SendCouponID()">Aplicar
                </v-btn>

              </v-row> -->

            </v-form>

          </div>

          <!-- <br> -->
          <br>

          <div v-if="screen > 800" class="planContainer">
            <PlanEscritorio v-for=" (plan, indice) in plans" :key="plan._id" :planData="plan" :index="indice"
              :userData="userData" :beneficiaries="beneficiaries" :loadingPlans="loadingPlans" @next-step="validate" />
          </div>

          <div v-if="screen <= 800" class="planContainer">
            <PlanMovil :planData="plans" :index="indice" :userData="userData" :beneficiaries="beneficiaries"
              :loadingPlans="loadingPlans" @next-step="validate" />
          </div>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-row style="justify-content: center; margin-top: 1rem">
      <v-btn text @click="back()"> Atrás </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import PlanEscritorio from "@/components/PlanEscritorio.vue";
import PlanMovil from "@/components/PlanMovil.vue";
import ShieldService from "../services/shield.service";
import Titulo from "../components/Titulo.vue"
import NavBar from "@/components/NavBar.vue";
import swal from 'sweetalert';

export default {
  name: "PlanForm",
  components: { PlanEscritorio, PlanMovil, Titulo, NavBar },
  data: () => ({
    userData: {
      salud: null,
      old: null,
      couponId: null,
      typePlan: null,
    },
    plans: [],
    stepperColor: '#00968F',
    e1: 2,
    loadingPlans: false,
    labelDescuento: '',
    couponRules: [
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    screen: window.screen.width,
  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (this.screen > 800) {
      this.labelDescuento = 'labelDescuento'
    } else {
      this.labelDescuento = 'labelDescuento2'
    }

    this.userData.salud = localStorage.getItem('salud');
    this.userData.old = localStorage.getItem('old');

    if (!this.userData.salud || !this.userData.old) {

      swal({
        title: "¡Falta información!",
        text: "Por favor, complete nuevamente el flujo.",
        icon: "info",
        button: "Ok",
      }).then(() => {
        this.$router.push({ path: '/' });
      });
    }

    this.getPlans();
  },
  methods: {
    async SendCouponID() {
      if (this.$refs.form.validate()) {

        var plansResponseIndividual = await ShieldService.getPlans(
          this.userData.salud,
          'Individual',
          this.userData.old,
          this.userData.couponId,
        );

        var plansResponseFamiliar = await ShieldService.getPlans(
          this.userData.salud,
          'Familiar',
          this.userData.old,
          this.userData.couponId,
        );

        if (plansResponseIndividual.status != 'error' && plansResponseFamiliar.status != 'error') {

          swal({
            title: "¡Código válido!",
            text: "El descuento se ha aplicado exitosamente.",
            icon: "success",
            button: "De acuerdo",
          });

          this.plans = [];
          this.plans.push(plansResponseIndividual[0])
          this.plans.push(plansResponseFamiliar[0])

          this.loadingPlans = false;
          this.$forceUpdate();
        } else {
          this.userData.couponId = null
          swal({
            title: "Código no válido",
            text: "Lamentablemente no existen planes asociados a este código de descuento. Intenta con otro código.",
            icon: "info",
            button: "Ok",
          });
        }
      }
    },
    back() {
      this.$router.push({ path: '/' })
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    async getPlans() {
      this.loadingPlans = true;
      var planIndividual = await ShieldService.getPlans(this.userData.salud, 'Individual', this.userData.old, this.userData.couponId);
      var planFamiliar = await ShieldService.getPlans(this.userData.salud, 'Familiar', this.userData.old, this.userData.couponId);
      this.plans.push(planIndividual[0])
      this.plans.push(planFamiliar[0])
      this.loadingPlans = false;
      // console.log('plan individual: ',planIndividual)
    },
  },
};
</script>


<style lang="scss">
.labelTituloo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
}

.labelSubtitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.botonAplicar {
  border-radius: 0px 10px 10px 0px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 143.34%;
  text-transform: capitalize;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.labelDescuento2 {
  min-width: 30%;
  max-width: 72%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.planContainer {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.formData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>