<template>
  <v-container>

    <NavBar :step="0" />

    <Titulo></Titulo>

    <br>

    <br v-if="screen < 800">


    <v-stepper v-model="e1" elevation="0">

      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">
      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="1">
          <div :class="formText">

            <v-form ref="form" v-model="valid" max-witdh="300px">

              <br>
              <br>

              <h3 class="text-center labelHola">¡Hola!</h3>

              <h3 class="text-center labelQuestion">¿Cómo te llamas?</h3>
              <br>

              <v-row>
                <v-col>
                  <v-text-field class="labelNombre" v-model="userData.name" :rules="nameRules" label="Nombre" required
                    maxlength="51" filled solo @change="change()"></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field class="labelNombre" v-model="userData.surname" @change="change()" :rules="surnameRules"
                    maxlength="51" label="Apellido" required filled solo></v-text-field>
                </v-col>

              </v-row>
              <br>
              <br>

              <h3 class="text-center labelQuestion">¿Cuál es tu email?</h3>

              <h4 class="text-center labelSubQuestion">Así podremos enviarte tu cotización, descuentos y comunicaciones.
              </h4>

              <v-text-field class="labelEmail" v-model="userData.email" @change="change()" :rules="emailRules"
                maxlength="51" label="Ingresa tu correo electrónico" required filled solo></v-text-field>

              <br>
              <br>

              <h3 class="text-center labelQuestion">¿Cuál es la fecha de nacimiento?</h3>

              <h4 class="text-center labelSubQuestion">Es para recomendarte el mejor seguro.</h4>

              <v-row v-if="screen < 800">

                <v-col>
                  <v-select class="labelDatesMovil" style="max-width: 90px; height: 48px;" :rules="monthRules"
                    :items="months" v-model="month" :loading="loadingMonths" v-on:change="getDays()" item-text="name"
                    label="Mes" return-object required filled solo></v-select>
                </v-col>

                <v-col>
                  <v-select class="labelDatesMovil" style="max-width: 90px; height: 48px;" :rules="dayRules"
                    :items="days" v-model="day" :loading="loadingDays" :disabled="loadingDays || month === null"
                    item-text="name" label="Día" return-object required filled solo>
                  </v-select>
                </v-col>

                <v-col>
                  <v-select class="labelDatesMovil" style="max-width: 90px; height: 48px;" :rules="yearRules"
                    value="2022" :items="years" v-model="year" item-text="name" label="Año" return-object required
                    filled @change="changeBisiesto()" solo></v-select>
                </v-col>

              </v-row>

              <v-row v-else>

                <v-col>
                  <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="monthRules"
                    :items="months" v-model="month" :loading="loadingMonths" v-on:change="getDays()" item-text="name"
                    label="Mes" return-object required filled solo></v-select>
                </v-col>

                <v-col>
                  <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="dayRules"
                    :items="days" v-model="day" :loading="loadingDays" :disabled="loadingDays || month === null"
                    item-text="name" label="Día" return-object required filled solo>
                  </v-select>
                </v-col>

                <v-col>
                  <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="yearRules"
                    value="2022" :items="years" v-model="year" item-text="name" label="Año" return-object required
                    filled @change="changeBisiesto()" solo></v-select>
                </v-col>

              </v-row>

              <br>
              <br>

              <h3 class="text-center labelQuestion">¿Cuál es tu sistema de Salud?</h3>

              <h4 class="text-center labelSubQuestion">Esto nos ayuda a recomendarte el mejor plan disponible para
                protegerte</h4>

              <v-col>
                <v-radio-group :rules="ssRules" v-model="userData.salud" row>
                  <div style="display: flex; margin: auto;">
                    <v-radio style="font-size: 40px;" label="ISAPRE" value="Isapre" @change="change()">
                      <label class="labelSalud">Isapre</label>
                    </v-radio>
                    <v-radio style="font-size: 40px;" label="FONASA*" value="Fonasa" @change="change()">
                      <label class="labelSalud">Fonasa*</label>
                    </v-radio>
                  </div>
                </v-radio-group>
              </v-col>
              
              <h4 class="text-center labelSubQuestion">*No incluye FONASA “A” y PRAIS.</h4>

              <div class="formData">
                <v-row justify="center">
                  <v-dialog v-model="dialog" width="600px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Términos y Condiciones</span>
                      </v-card-title>
                      <v-card-text style="text-align: justify;">
                        Por este acto, y según lo dispuesto en la Ley N° 19.628 sobre Protección de la Vida Privada y
                        sus
                        modificaciones, autorizo expresamente a Southbridge
                        Compañía de Seguros Generales S.A. para que haga tratamiento, almacene, transmita y comunique
                        mis
                        datos
                        personales y la información que le he
                        proporcionado voluntariamente, a su matriz, filiales, sociedades coligadas, empresas
                        relacionadas,
                        sociedades del Grupo Empresarial al que pertenece y
                        terceros prestadores de servicios, estén ubicados dentro o fuera de Chile, con el propósito de
                        que
                        tanto
                        Southbridge Compañía de Seguros Generales
                        S.A. como dichas entidades i) me contacten y pongan a mi disposición, en la forma que estimen
                        pertinente, la más completa información y oferta de sus
                        productos y servicios; ii) procedan al almacenamiento y tratamiento de mis datos personales y de
                        mi
                        información personal para los objetivos del
                        cumplimiento de este Contrato de Seguro; y iii) hagan efectivos el o los convenios que pudieren
                        estar
                        asociados a los seguros que he contratado. Además,
                        por este acto, autorizo expresamente a que cualquier información, comunicación, declaración o
                        notificación que debe efectuar Southbridge Compañía de
                        Seguros Generales S.A. en virtud del presente contrato de seguros sea realizada a través de
                        correo
                        electrónico o de cualquier sistema de transmisión o
                        registro digital de la palabra escrita o verbal o por cualquier otro medio de comunicación
                        fehaciente.
                        El asegurado declara que conoce, acepta y concuerda
                        que cualquier transacción que se realice en este sitio con su Rut se
                        entenderá y se reputará, para todos los efectos
                        legales, como realizada por él mismo. En tal sentido, Southbridge Compañía de Seguros Generales S.A.
                        no tiene ninguna responsabilidad por el uso indebido de la información empleada por un tercero
                        distinto del asegurado. El asegurado se hace responsable por la veracidad de la información 
                        entregada para la contratación del seguro y del uso de las claves o códigos, liberando a Southbridge 
                        Compañía de Seguros Generales S.A. de responsabilidad por cualquier mal uso que hagan terceros de la
                        información que Southbridge Compañía de Seguros Generales S.A. entregue exclusivamente a la persona del
                        asegurable.
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                                  border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                                  letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
                          elevation="0" text @click="dialog = false">
                          Aceptar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>


                <v-hover v-if="screen > 800">
                  <v-checkbox :rules="conditionsRules" :color="stepperColor" required>
                    <template v-slot:label>
                      <p
                        style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323; opacity: 0.8; text-align: justify; max-width: 400px; margin-top: 10%;">
                        Al ingresar tus datos y hacer clic en continuar aceptas que tu información sea utilizada para
                        calcular tu estimación de prima y comunicarnos contigo para éste y otros productos de acuerdo a
                        nuestros <b><a style="color:rgba(0, 44, 119, 1)" @click="dialog = true">Términos y
                            Condiciones</a></b></p>
                    </template>
                  </v-checkbox>
                </v-hover>

                <v-hover v-else>
                  <v-checkbox :rules="conditionsRules" :color="stepperColor" required>
                    <template v-slot:label>
                      <p
                        style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323; opacity: 0.8; text-align: justify; margin-top: 20%;">
                        Al ingresar tus datos y hacer clic en continuar aceptas que tu información sea utilizada para
                        calcular tu estimación de prima y comunicarnos contigo para éste y otros productos de acuerdo a
                        nuestros <b><a style="color:rgba(0, 44, 119, 1)" @click="dialog = true">Términos y
                            Condiciones</a></b></p>
                    </template>
                  </v-checkbox>
                </v-hover>
              </div>

              <div class="button-container">
                <v-btn
                  style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 300px; height: 50px;"
                  @click="validate()">
                  Continuar
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
import Titulo from "../components/Titulo.vue"
import NavBar from "@/components/NavBar.vue";
import swal from 'sweetalert';
const moment = require('moment');

import ShieldService from "../services/shield.service";

export default {
  name: "Step1",
  components: { Titulo, NavBar },
  data: () => ({
    version: null,
    formText: '',
    stepperColor: '#00968F',
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      address: null,
      email: null,
      phoneNumber: null,
      hasAcode: null,
      couponId: null,
      dates: null,
      stepId: null,
      old: null,
      salud: null,
      day: null,
      month: null,
      year: null,
      questionOne: null,
      questionTwo: null,
      questionThree: null,
      typePlan: null,
    },
    edadMayor: null,
    storedDate: null,
    dialog: false,
    screen: window.screen.width,
    months: [],
    days: ["Selecciona el mes"],
    yars: [],
    prefijoTelefono: null,
    benf: [
      "Individual",
      "Familiar",
    ],
    ss: [
      "Fonasa",
      "Isapre",
    ],
    valid: false,
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "El nombre es requerido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "El apellido es requerido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    ssRules: [(v) => !!v || "Seleccione sistema de Salud"],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    dayRules: [
      (v) => !!v || "El día es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ],
    saludRules: [(v) => !!v || "Seleccione una opción"],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],

  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.getMonths();
    this.getYears();

    this.userData.name = localStorage.getItem('name')
    this.userData.surname = localStorage.getItem('surname')
    this.userData.email = localStorage.getItem('email')
    // this.userData.old = localStorage.getItem('old')
    this.userData.salud = localStorage.getItem('salud')

    if (this.screen > 800) {
      this.formText = 'formData'
    } else {
      this.formText = ''
    }

    this.storedDate = localStorage.getItem('dates');
    if (this.storedDate) {
      this.getMonthDays()
    }

  },
  methods: {
    async getMonthDays() {

    // console.log(this.storedDate);
    const [year, monthLocal, daylocal] = this.storedDate.split('-');

    // Logica Años
    this.year = await this.getYears(
      year
      );

    // console.log(this.years);
    // console.log(year);

    function findPosition(startYear, targetYear) {
        return startYear - targetYear;
    }
    // console.log(this.years[findPosition(2024, year)]);
    this.year = this.years[findPosition(2024, year)] 

    // Logica Meses

    if (monthLocal === '01') {
        this.monthLocalName = 'Enero';
    } else if (monthLocal === '02') {
        this.monthLocalName = 'Febrero';
    } else if (monthLocal === '03') {
        this.monthLocalName = 'Marzo';
    } else if (monthLocal === '04') {
        this.monthLocalName = 'Abril';
    } else if (monthLocal === '05') {
        this.monthLocalName = 'Mayo';
    } else if (monthLocal === '06') {
        this.monthLocalName = 'Junio';
    } else if (monthLocal === '07') {
        this.monthLocalName = 'Julio';
    } else if (monthLocal === '08') {
        this.monthLocalName = 'Agosto';
    } else if (monthLocal === '09') {
        this.monthLocalName = 'Septiembre';
    } else if (monthLocal === '10') {
        this.monthLocalName = 'Octubre';
    } else if (monthLocal === '11') {
        this.monthLocalName = 'Noviembre';
    } else if (monthLocal === '12') {
        this.monthLocalName = 'Diciembre';
    }
    // console.log(this.monthLocalName);
    // console.log(monthLocal);

    this.month = await this.getMonths(
    );
    const monthNumber = (parseInt(monthLocal, 10) - 1)
    const monthString = monthNumber.toString();
    // console.log(this.months[monthString]);
    this.month = this.months[monthString] 

    //Logica Dias

    this.days = await dateService.getDays(
      this.monthLocalName
    );
    // console.log(this.days);

    const dayNumber = (parseInt(daylocal, 10) - 1)
    const dayString = dayNumber.toString();
    // console.log(dayString);
    // console.log(this.days[dayString]);

    this.day = this.days[dayString] 
    // console.log(this.day);

},
    async changeBisiesto() {
      if (this.month && this.year && this.days && this.month.name == 'Febrero' && !this.isBisiesto(this.year.id) && this.days.length == 29) {
        this.day = null;
        this.days = await dateService.getDays(
          this.month.name, false
        );
      } else if (this.month && this.year && this.days && this.month.name == 'Febrero' && this.isBisiesto(this.year.id) && this.days.length == 28) {
        this.days = await dateService.getDays(
          this.month.name, true
        );
      } else {
        this.days = await dateService.getDays(
          this.month.name, null
        );
      }
    },
    isBisiesto(anio) {
      return (anio % 4 === 0 && anio % 100 !== 0) || (anio % 400 === 0);
    },
    async validate() {
      const fechaActual = new moment(new Date());
      if (this.$refs.form.validate()) {
        if (this.year && this.day && this.month) {
          var newDate = this.year.id + "-" + this.month.number + "-" + this.day.id
          var dateNacimiento = moment(new Date(newDate));
          var timesDiff = fechaActual.diff(dateNacimiento, 'years');
          if (timesDiff < 18) {
            swal({
              title: "Atención!",
              text: "Para contratar este seguro, usted debe ser mayor de edad!",
              icon: "info",
              button: "De acuerdo",
            });
          } else if (timesDiff > 79) {
            swal({
              title: "Atención!",
              text: "Para contratar este seguro, usted debe ser menor a 80 años!",
              icon: "info",
              button: "De acuerdo",
            });
          } else {
            this.userData.dates = newDate;
            this.userData.old = timesDiff;
            localStorage.setItem("name", this.userData.name);
            localStorage.setItem("surname", this.userData.surname);
            localStorage.setItem("email", this.userData.email);
            localStorage.setItem("old", timesDiff);
            localStorage.setItem("edadMayor", timesDiff);
            localStorage.setItem("dates", this.userData.dates);
            localStorage.setItem("salud", this.userData.salud);
            this.change();

            const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email)
            // console.log(activeCampaingRes.data)

            if (activeCampaingRes.data.data.meta.total == 1) {
              var contactID = activeCampaingRes.data.data.contacts[0].id;
              localStorage.setItem("contactID", contactID);
              const shieldRes = await ShieldService.updateContactActiveCampain(this.userData, null, contactID, 0);
              console.log(shieldRes.data)
            } else {
              const shieldRes = await ShieldService.createContactActiveCampain(this.userData);

              if (shieldRes.data.status == 'ok') {
                localStorage.setItem("contactID", shieldRes.data.data.contacts[0].id)
              }
            }

            this.$router.push({ path: '/planes' })
          }

        } 
        // else {
        //   swal({
        //     title: "Atención!",
        //     text: "Ingrese una fecha de nacimiento válida!",
        //     icon: "info",
        //     button: "De acuerdo",
        //   });
        // }
      }
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.month) {
        this.loadingDays = true;
        this.day = null;
        this.changeBisiesto();
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    async change() {

      var nombre = ''

      if (this.userData.name != null && this.userData.surname != null) {
        nombre = this.userData.name + ' ' + this.userData.surname
      } else if (this.userData.name != null && this.userData.surname == null) {
        nombre = this.userData.name
      } else if (this.userData.name == null && this.userData.surname != null) {
        nombre = this.userData.surname
      } else if (this.userData.name == null && this.userData.surname == null) {
        nombre = ''
      }

      var values;
      if (this.version == null) {

        values = [
          nombre,
          this.userData.email,
          this.userData.salud,
          this.userData.old != null ? this.userData.old.name : ''
        ]
        this.version = 1
      } else if (this.version != null) {
        values = [
          nombre,
          this.userData.email,
          this.userData.salud,
          this.userData.old != null ? this.userData.old : ''
        ]
      }
      try {

        var carro = await ShieldService.createStep(values, 1, this.version, this.userData.stepId)
        this.userData.stepId = carro.data.data._id
        localStorage.setItem("stepId", this.userData.stepId);
        this.version = 2
      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.labelHola {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  mix-blend-mode: darken;
}

.labelNombre {
  box-sizing: border-box;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  margin: auto;
  border-radius: 6px;
}

.labelQuestion {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
}

.labelSubQuestion {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelEmail {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelEdad {
  max-width: 163px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelSalud {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  color: rgba(0, 44, 119, 1);
  opacity: 0.75;
  mix-blend-mode: normal;
  height: 16px;
}

.step-date {
  max-width: 200px;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 150px;
  }
}

.labelDatesMovil {
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelDatesdesktop {
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
  min-height: 57px;
}

.v-input .v-label {
  font-size: 12px;
  color: #8E8E8E;
}

.formData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-messages__message {
  padding-bottom: 10%;
  text-align: center;
}

.v-btn__content {
  padding: 0.75rem;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.formData form.v-form div.col div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center !important;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.formData form.v-form div.formData div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--checkbox.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center !important;
}

#app>div>main>div>div>div.v-stepper.v-sheet.theme--light.elevation-0>div.v-stepper__items>div>div>div>form>div.col>div>div>div.v-input__slot>div>div>div:nth-child(2)>label {
  font-size: 15px;
  color: black;
}

#app>div>main>div>div>div.v-stepper.v-sheet.theme--light.elevation-0>div.v-stepper__items>div>div>div>form>div.col>div>div>div.v-input__slot>div>div>div:nth-child(1)>label {
  font-size: 15px;
  color: black;
}
</style>