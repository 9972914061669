<template>
  <v-container>

    <NavBar :step="4"></NavBar>

    <Titulo></Titulo>

    <br v-if="screen < 800">
    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="5">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>
                  <br>

                  <h3 class="text-center labelTitulo">Ingresa los datos de tu familia</h3>

                  <h4 class="text-center labelSubTitulo">
                    Como elegiste un plan familiar, debes agregar la información personal de tu grupo familiar. Es
                    importante completarlo correctamente para no afectar la cobertura.
                  </h4>

                  <br>
                  <br>

                  <div v-for="(benef, index) in beneficiaries" :key="index">

                    <v-expansion-panels :value="benef.panel" class="labelPanel">

                      <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">

                        <v-expansion-panel-header style="height: 48px;">

                          <label>{{ benef.tipo }}</label>

                          <v-icon v-if="!checkImage(benef, index) || benef.validRut == false"
                            color="red">mdi-alert-circle</v-icon>

                          <v-img
                            v-if="!benef.name == false && !benef.surname == false && benef.validRut == true && !benef.date == false"
                            contain :src="ticket" @load="allImagesPresent = checkAllImagesPresent()" max-width="18px"
                            max-height="18px" />

                        </v-expansion-panel-header>

                        <v-expansion-panel-content>


                          <div class="form-box">

                            <br>

                            <h4 class="text-center labelEtiqueta">
                              Nombre
                            </h4>

                            <v-text-field class="labelsEntrada" v-model="benef.name" :rules="nameRules" label="Nombre"
                              @change="addLocalStorage()" maxlength="51" style="margin: auto;" required filled
                              solo></v-text-field>

                            <br>
                            <br>

                            <h4 class="text-center labelEtiqueta">
                              Apellido
                            </h4>

                            <v-text-field class="labelsEntrada" v-model="benef.surname" :rules="surnameRules"
                              maxlength="51" @change="addLocalStorage()" label="Apellido" required filled solo
                              style="margin: auto;">
                            </v-text-field>

                            <br>
                            <br>

                            <h4 class="text-center labelEtiqueta">
                              RUT
                            </h4>

                            <v-text-field class="labelsEntrada" v-model.lazy="benef.newRut" :rules="rutRules"
                              maxLength="12" @change="addLocalStorage()" label="" required filled solo
                              style="margin: auto;" @input="changeRut(index)"></v-text-field>

                            <br>
                            <br>

                            <h4 class="text-center labelEtiquetaFecha">
                              Fecha de Nacimiento
                            </h4>

                            <div>
                              <v-row>
                                <v-col>
                                  <v-select class="labelDate" :items="benef.months" v-model="benef.month"
                                    :rules="monthRules" :loading="loadingMonths" @change="addLocalStorage()"
                                    v-on:change="getDays(index)" item-text="name" label="Mes" return-object required
                                    filled solo>
                                  </v-select>
                                </v-col>

                                <br v-if="screen < 800">
                                
                                <v-col>
                                  <v-select class="labelDate" :items="benef.days" v-model="benef.day" :rules="dayRules"
                                    :loading="loadingDays" :disabled="loadingDays || benef.month === null"
                                    item-text="name" label="Día" return-object required filled solo
                                    @change="setDate(index)">
                                  </v-select>
                                </v-col>

                                <br v-if="screen < 800">

                                <v-col>
                                  <v-select class="labelDate" value="2022" :items="benef.years" v-model="benef.year"
                                    :rules="yearRules" item-text="name" label="Año" return-object required filled solo
                                    @change="setDate(index)">
                                  </v-select>
                                </v-col>

                                <br v-if="screen < 800">

                              </v-row>
                            </div>

                            <br>

                            <div class="button-container">
                              <v-btn class="botonGuardar" style="background-color: rgba(0, 44, 119, 1); color: white;"
                                @click="save(index)">
                                Guardar
                              </v-btn>
                            </div>

                            <br>

                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <br>
                    </v-expansion-panels>

                  </div>

                  <br>

                  <div class="button-container">
                    <v-btn class="botonAgregar" style="background-color: #FFB600; color: white;"
                      @click="addBeneficiary()">
                      AGREGAR OTRO FAMILIAR
                    </v-btn>
                    <br>
                    <br>
                    <br>
                  </div>

                  <div class="button-container">
                    <v-btn class="botonContinuar" style="background-color: rgba(0, 44, 119, 1); color: white;"
                      @click="validate()">
                      Continuar
                    </v-btn>

                    <br>
                    <br>

                    <v-btn text @click="back()">
                      Atrás
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import RutUtils from "@/utils/rut.utils.js";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";

const moment = require('moment');
import swal from 'sweetalert';

import NavBar from "@/components/NavBar.vue";
import ticket from "@/assets/images/ticket.png"
import Titulo from "../components/Titulo.vue"

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    beneficiaries: [],
    storedDate: null,
    allImagesPresent: false,
    ticket,
    loadingDays: false,
    screen: window.screen.width,
    stepperColor: '#00968F',
    stepId: null,
    options: [
      { text: "Hija", value: "opcion1" },
      { text: "Hijo", value: "opcion2" },
      { text: "Cónyuge", value: "opcion3" },
    ],
    selectedOption: "",
    fieldsToShow: [],
    showTextField: false,
    e1: 5,
    month: null,
    day: null,
    year: null,
    monthsArray: null,
    daysArray: null,
    yearsArray: null,
    valid: false,
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => (v && v.length >= 11 && v.length <= 12) || "Rut no válido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    nameRules: [
      // (v) => !!v || "El nombre es requerido",
      (v) => !!v.trim() || "El nombre es requerido",
      // (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    surnameRules: [
      // (v) => !!v || "El apellido es requerido",
      (v) => !!v.trim() || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s-]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    dayRules: [
      (v) => !!v || "La día es requerido",
    ],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ],
    newRut: "",
  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    var redirection = localStorage.getItem('QOne') || '';
    if (redirection === '') {
      swal({
        title: "¡Falta información!",
        text: "Por favor, complete nuevamente el flujo.",
        icon: "info",
        button: "Ok",
      }).then(() => {
        this.$router.push({ path: '/' });
      });
    } else{

      this.getMonthsFull();
      this.getYearsFull();

      this.stepId = localStorage.getItem("stepId");
      this.lengthBenef = localStorage.getItem('cantBenef');
      
      this.getBeneficiaries();

      // console.log('beneficiaries: ',this.beneficiaries)

      if (this.screen > 800) {
        this.vista = "vista2";
      } else {
        this.vista = "vista";
      }
    }
  },
  methods: {
    async getMonthsFull() {
      this.loadingMonths = true;
      this.monthsArray = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getYearSelected(yearId) {
      this.loadingYears = true;
      var year = await yearService.getYearsFilter(yearId);
      this.loadingYears = false;
      return year
    },
    async getMonthSelected(monthNumber) {
      this.loadingMonths = true;
      var month = await dateService.getMonthFilter(monthNumber);
      this.loadingMonths = false;
      return month
    },
    async getYearsFull() {
      this.loadingYears = true;
      this.yearsArray = await yearService.getYears();
      this.loadingYears = false;
    },
    async getMonthDays(y,m,d,index) {
      
      const [yearLocal, monthLocal, daylocal] = [y,m,d];
      // console.log(monthLocal + "-"+daylocal + "-" + yearLocal)
      var auxYear = await this.getYearSelected(yearLocal);
      this.beneficiaries[index].year = auxYear[0]

      // // Logica Meses
      var auxMonth = await this.getMonthSelected(monthLocal);
      this.beneficiaries[index].month = auxMonth[0]

      // //Logica Dias
      var auxDays = auxMonth[0].days

      console.log('auxMonthDays: ',auxDays)

      auxDays.forEach(day => {
        if (day.id == daylocal){
          // console.log(day)
          this.beneficiaries[index].day = day
          return
        }
      });
      this.beneficiaries[index].days = auxDays
      this.setDate(index)
 },
    setDate(index) {
      const fechaActual = new moment(new Date());
      console.log("beneficiarie:",this.beneficiaries[index])
      // console.log("dia:",this.beneficiaries[index].day)
      // console.log("año:",this.beneficiaries[index].year)
      if (this.beneficiaries[index].month != null && this.beneficiaries[index].month != 'undefined' && this.beneficiaries[index].day != null &&  this.beneficiaries[index].day != 'undefined' && this.beneficiaries[index].year != null && this.beneficiaries[index].year != 'undefined') {
        var date = '' + this.beneficiaries[index].month.number + '-' + this.beneficiaries[index].day.id + '-' + this.beneficiaries[index].year.name;
        var dateNacimiento = moment(new Date(date));
        // console.log('date:',date)
        if (dateNacimiento.isSameOrBefore(fechaActual, 'day')) {
          var timesDiff = fechaActual.diff(dateNacimiento, 'years');
          // console.log("fecha: ", timesDiff)
          // console.log("fecha: ", this.beneficiaries[index].edad)
          if (timesDiff == this.beneficiaries[index].edad) {
            this.beneficiaries[index].date = '' + this.beneficiaries[index].day.id + '-' + this.beneficiaries[index].month.number + '-' + this.beneficiaries[index].year.name;
          }
          else {
            this.beneficiaries[index].date = false

            swal({
              title: "Atención!",
              text: "La fecha de nacimiento no coincide con la edad de beneficiario.",
              icon: "info",
              button: "De acuerdo",
            });
          }
        }  
        // else {
        //   this.beneficiaries[index].date = false
        //     swal({
        //       title: "Atención!",
        //       text: "La fecha de nacimiento no es válida.",
        //       icon: "info",
        //       button: "De acuerdo",
        //     });
        // }
      }
      this.changeBisiesto(index);
      this.addLocalStorage();
    },
    async changeBisiesto(index) {
      if (this.beneficiaries[index].month && this.beneficiaries[index].year && this.beneficiaries[index].days && this.beneficiaries[index].month.name == 'Febrero' && !this.isBisiesto(this.beneficiaries[index].year.id) && this.beneficiaries[index].days.length == 29) {
        console.log("hola")
        // this.beneficiaries[index].day = null;
        this.beneficiaries[index].date = null;
        this.beneficiaries[index].days = await dateService.getDays(
          this.beneficiaries[index].month.name, false
        );
        this.daysArray = this.beneficiaries[index].days
      } else if (this.beneficiaries[index].month && this.beneficiaries[index].year && this.beneficiaries[index].days && this.beneficiaries[index].month.name == 'Febrero' && this.isBisiesto(this.beneficiaries[index].year.id) && this.beneficiaries[index].days.length == 28) {
        // console.log("hola2")
        this.beneficiaries[index].days = await dateService.getDays(
          this.beneficiaries[index].month.name, true
        );
        this.daysArray = this.beneficiaries[index].days
      } else {
        // console.log("hola3")
        this.beneficiaries[index].days = await dateService.getDays(
          this.beneficiaries[index].month.name, null
        );
        this.daysArray = this.beneficiaries[index].days
      }
      // console.log("beneficiario final", this.beneficiaries[index])
    },
    isBisiesto(anio) {
      return (anio % 4 === 0 && anio % 100 !== 0) || (anio % 400 === 0);
    },
    save(index) {
      if (this.beneficiaries[index].name.trim() && this.beneficiaries[index].surname.trim() && this.beneficiaries[index].day && this.beneficiaries[index].month && this.beneficiaries[index].year) {
        if (this.beneficiaries[index].panel == 0) {
          this.beneficiaries[index].panel = 1
        } else if (this.beneficiaries[index].panel == 1) {
          this.beneficiaries[index].panel = 0
        }
      } else {
        swal({
          title: "Atención!",
          text: "Debe ingresar todos los campos requeridos",
          icon: "info",
          button: "De acuerdo",
        });
      }
    },
    async getBeneficiaries() {
      var contador = 0;
      var olds = localStorage.getItem('benefOlds') != null ? localStorage.getItem('benefOlds').split(';') : null;
      var types = localStorage.getItem('benefTypes') != null ? localStorage.getItem('benefTypes').split(';') : null;
      var names = localStorage.getItem('benefNames') != null ? localStorage.getItem('benefNames').split(';') : null;
      var surnames = localStorage.getItem('benefSurnames') != null ? localStorage.getItem('benefSurnames').split(';') : null;
      var ruts = localStorage.getItem('benefRuts') != null ? localStorage.getItem('benefRuts').split(';') : null;
      var days = localStorage.getItem('benefDays') != null ? localStorage.getItem('benefDays').split(';') : null;
      var months = localStorage.getItem('benefMonths') != null ? localStorage.getItem('benefMonths').split(';') : null;
      var years = localStorage.getItem('benefYears') != null ? localStorage.getItem('benefYears').split(';') : null;

      // console.log('years: ',years)
      // console.log('months: ',months)
      // console.log('days: ',days)

      while (this.lengthBenef != contador) {
        var person = {
          validRut: false,
          tipo: types != null ? types[contador] : '',
          edad: olds != null ? olds[contador] : '',
          name: names != null ? names[contador] : '',
          surname: surnames != null ? surnames[contador] : '',
          rut: ruts != null ? ruts[contador] : '',
          days: ['Selecciona el mes'],
          months: [],
          years: years != null ? years[contador] : '',
          date: null,
          newRut: ruts != null ? ruts[contador] : '',
          panel: 0,
          year: years != null ? years[contador] : '',
          month: months != null ? months[contador] : '',
          day: days != null ? days[contador] : '',
        }
        // console.log('person:', person)
        this.beneficiaries.push(person)
      

        await this.getMonths(contador);
        await this.getYears(contador);
        // console.log('this.monthArray: ',this.monthsArray)
        // console.log('this.yearArray: ',this.yearsArray)
        this.getMonthDays(person.year,person.month,person.day,contador);
        contador++;
      }
    },
    addBeneficiary() {
      this.$router.push({ path: '/beneficiarios' })
    },
    async getMonths(index) {
      this.loadingMonths = true;
      this.beneficiaries[index].months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays(index) {
      if (this.beneficiaries[index].month) {
        console.log("hola2")

        // this.days = this.beneficiaries[index].days
        this.loadingDays = true;
        this.beneficiaries[index].day = null;
        this.beneficiaries[index].days = await dateService.getDays(this.beneficiaries[index].month.name);
        this.changeBisiesto(index);
        this.loadingDays = false;
      }
    },
    async getYears(index) {
      this.loadingYears = true;
      this.beneficiaries[index].years = await yearService.getYears();
      this.loadingYears = false;
    },
    validate() {
      // console.log(this.allImagesPresent)
      if (this.$refs.form.validate() && this.allImagesPresent === true) {
        this.$router.push({ path: '/datos-contratante' })
      } else {
        swal({
          title: "Atención!",
          text: "Debe ingresar y validar todos los campos requeridos",
          icon: "info",
          button: "De acuerdo",
        });
        this.allImagesPresent = false
      }
    },
    checkAllImagesPresent() {
      // console.log("beneficiarios: ", this.beneficiaries)
      return this.beneficiaries.every(benef => benef.name && benef.surname && benef.rut && benef.date);
    },
    checkImage(benef, index) {
      // console.log(benef.rut.length)
      if (RutUtils.validateRut(benef.rut) && benef.rut != '' && benef.rut.length > 11) {
        this.beneficiaries[index].validRut = true
      } else {
        this.beneficiaries[index].validRut = false
      }
      return !!(benef.name && benef.surname && RutUtils.validateRut(benef.rut) && benef.date);
    },
    changeRut(index) {

      const cleanRut = RutUtils.cleanRut(this.beneficiaries[index].newRut);
      const formattedRut = RutUtils.formatRut(this.beneficiaries[index].newRut);



      const isRutDuplicated = this.beneficiaries.some((beneficiary, idx) => {
        if (beneficiary.rut.trim() !== '' && this.beneficiaries[index].newRut.trim() !== '') {
          return idx !== index && RutUtils.cleanRut(beneficiary.rut) === cleanRut;
        } else {
          return false; 
        }   
      });

      if (isRutDuplicated) {
        swal({
          title: "Atención!",
          text: "Tu RUT no puede coincidir con el de otro beneficiario!",
          icon: "info",
          button: "De acuerdo",
        });
      } else {
        this.beneficiaries[index].rut = formattedRut;
        this.beneficiaries[index].newRut = formattedRut;
        this.addLocalStorage();
      }
    },
    back() {
      this.$router.push({ path: '/dps' })
    },
    addLocalStorage() {
      var names = ''
      var surnames = ''
      var ruts = ''
      var days = ''
      var months = ''
      var years = ''
      this.beneficiaries.forEach(benef => {
        names = benef.name ? names + benef.name + ';' : names + '' + ';';
        surnames = benef.surname ? surnames + benef.surname + ';' : surnames + '' + ';';
        ruts = benef.rut ? ruts + benef.rut + ';' : ruts + '' + ';';
        days = benef.day ? days + benef.day.id + ';' : days + null + ';';
        months = benef.month ? months + benef.month.number + ';' : months + null + ';';
        years = benef.year ? years + benef.year.id + ';' : years + null + ';';
      });
      localStorage.setItem('benefNames', names)
      localStorage.setItem('benefSurnames', surnames)
      localStorage.setItem('benefRuts', ruts)
      localStorage.setItem('benefDays', days)
      localStorage.setItem('benefMonths', months)
      localStorage.setItem('benefYears', years)
    }
  },
};
</script>

<style lang="scss">
.labelTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  margin-top: -5%;
}

.labelSubTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelPanel {
  margin-bottom: 5%;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  margin: auto;
  max-width: 98%;
  margin-top: 20px;
}

.labelsEntrada {
  box-sizing: border-box;
  height: 57px;
  max-width: 85%;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelDate {
  max-width: 135px;
  height: 57px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.botonAgregar {
  border-radius: 12px;
  width: 214px;
  height: 39px;
  font-size: 13px;
}

.labelEtiqueta {
  font-family: Helvetica;
  font-style: normal;
  width: 147px;
  height: 25px;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #5a5a5a;
  letter-spacing: -0.01em;
}

@media only screen and (max-width: 767px) {
  .labelEtiqueta {
    padding-right: 17%;
    font-family: Helvetica;
    font-style: normal;
    height: 25px;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #5a5a5a;
    letter-spacing: -0.01em;
  }
}

.labelEtiquetaFecha {
  font-family: Helvetica;
  font-style: normal;
  width: 147px;
  height: 25px;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #5a5a5a;
  letter-spacing: -0.01em;
  margin-left: 25%;
}

@media only screen and (max-width: 767px) {
  .labelEtiquetaFecha {
    font-family: Helvetica;
    font-style: normal;
    width: 147px;
    height: 25px;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #5a5a5a;
    letter-spacing: -0.01em;
    margin-left: 18%;
  }
}

.botonContinuar {
  border-radius: 12px;
  width: 310px;
  height: 100%;
}

.botonGuardar {
  border-radius: 12px;
  width: 250px;
  height: 100%;
}

.vista {
  // max-width: 1000px;
  max-width: 100% !important;
}

.vista2 {
  min-width: 500px;
  max-width: 70% !important;
}

.step-data {
  max-width: 20%;
  align-items: center;
  // display: flex;

  justify-content: center;

  .v-select {
    max-width: 120px;
  }
}

.col {
  max-width: 89%;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

.form-box {
  box-sizing: border-box;
  background: #f3f3f3;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  border-radius: 6px;
  margin: auto;
}

.v-input .v-label {
  font-size: 12px;
  color: #8e8e8e;
}

// Escritorio
html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel.v-expansion-panel--active.v-item--active div.v-expansion-panel-content div.v-expansion-panel-content__wrap div.form-box div div.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 1px;
}

// Movil
html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel.v-expansion-panel--active.v-item--active div.v-expansion-panel-content div.v-expansion-panel-content__wrap div.form-box div div.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 1px;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel.v-expansion-panel--active.v-item--active div.v-expansion-panel-content div.v-expansion-panel-content__wrap div.form-box div div.row div.col {
  max-width: 68%;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel button.v-expansion-panel-header i.v-icon.notranslate.mdi.mdi-alert-circle.theme--light.red--text {
  justify-content: end;
}
</style>
